<template>
  <div class="grid">
    <div class="col-12">
      <div class="card">
        <Toast/>
        <Toolbar class="mb-4">
          <template v-slot:start>
            <div class="my-2">
              <Button label="Ajouter" icon="pi pi-plus" class="p-button-success mr-2" @click="openNew" />
            </div>
          </template>
        </Toolbar>
        <DataTable :value="this.salles" :paginator="true" :rows="10" :loading="this.loading"
                   paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="[5,10,25]"
                   currentPageReportTemplate="{first} à {last} des {totalRecords} salles" responsiveLayout="scroll">
          <template #header>
            <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
              <h5 class="m-0">Liste des salles</h5>
            </div>
          </template>

          <Column field="login" header="nom de la salle" :sortable="true" headerStyle="width:14%; min-width:10rem;">
            <template #body="slotProps">

              {{ slotProps.data.name}}

              <span class="p-column-title">Identifiant</span>
            </template>
          </Column>

          <Column style="text-align: right">
            <template #body="slotProps">
              <Button icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2" @click="editSalle(slotProps.data)" />
              <Button icon="pi pi-trash" class="p-button-rounded p-button-warning mt-2" @click="confirmDeleteSalle(slotProps.data)" />
            </template>
          </Column>
        </DataTable>


        <Dialog v-model:visible="createSalleDialog" :style="{width: '450px'}" header="Créer une salle" :modal="true" class="p-fluid">
          <form v-on:submit.prevent="createSalle" ref="salleForm">
            <ak-input-text required="true" v-model="this.salle.name" label="Nom" :validator="v$.salle.name" :submitted="this.submitted"></ak-input-text>
            <ak-input-text required="true" v-model="this.salle.address" label="Adresse" :validator="v$.salle.address" :submitted="this.submitted"></ak-input-text>
            <ak-input-text required="true" v-model="this.salle.city" label="Ville" :validator="v$.salle.city" :submitted="this.submitted"></ak-input-text>
            <ak-input-text required="true" v-model="this.salle.zipcode" label="Code postal" :validator="v$.salle.zipcode" :submitted="this.submitted"></ak-input-text>
            <ak-input-number required="true" v-model="this.salle.nbPlace" label="Nombre de places" :validator="v$.salle.nbPlace" :submitted="this.submitted"></ak-input-number>
            <ak-checkbox :required=true v-model="this.salle.isHandicapped" label="Accès handicapé ?" :validator="v$.salle.isHandicapped" :submitted="this.submitted"></ak-checkbox>
            <div class="p-dialog-footer">
             <!-- Bouton pour valider le formulaire (il est différent des autres) -->
              <button class="p-button p-component p-button-text">
                <span class="pi pi-check p-button-icon p-button-icon-left"></span>
                <span class="p-button-label">Ajouter</span></button>
            </div>
          </form>
        </Dialog>

        <Dialog v-model:visible="deleteSalleDialog" :style="{width: '450px'}" header="Confirmer" :modal="true">
          <div class="flex align-items-center justify-content-center">
            <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
            <span v-if="salle">Voulez-vous vraiment supprimer la salle <b>{{salle.name}}</b> ?</span>
          </div>
          <div class="p-dialog-footer">
            <Button label="Non" icon="pi pi-times" class="p-button-text" @click="deleteSalleDialog = false"/>
            <Button label="Oui" icon="pi pi-check" class="p-button-text" @click="deleteSalle" />
          </div>
        </Dialog>

      </div>
    </div>
  </div>

</template>

<script>
import SalleService from "@/service/SalleService";
import AkInputText from "@/components/input/AkInputText";
import AkInputNumber from "@components/input/AkInputNumber";
import AkCheckbox from "@components/input/AkCheckbox";
import useVuelidate from "@vuelidate/core";
import {required} from "@vuelidate/validators";


export default {
  components: {AkInputText, AkInputNumber, AkCheckbox},

  setup() {
    return {v$: useVuelidate()}
  },

  data() {
    return {
      salles:[],
      salle:{
        isHandicapped: false
      },
      createSalleDialog: false,
      isCreation : false,
      deleteSalleDialog : false,
      filters: {},
      submitted: false,
      loading: true,
      statuses: [
      ]
    }
  },

  validations() {
    return {
      salle: {
        name: {required},
        address: {required},
        city: {required},
        zipcode: {required},
        nbPlace: {required},
        isHandicapped: {required},
      }
    }
  },

  salleService: null,
  created() {
    this.salleService = new SalleService();

  },

  mounted() {
    this.salleService.salles().then(data => {
      this.salles = data;
      this.loading = false;
    });

  },
  methods: {
    openNew() {
      this.user = {};
      this.submitted = false;
      this.createSalleDialog = true;
    },
    hideDialog() {
      this.createSalleDialog = false;
      this.submitted = false;
    },

    createSalle(){
      let _this = this;
      this.submitted = true;
      this.v$.$touch();
      if (this.v$.$error) return;

      if(this.salle.id!== undefined){
        this.salleService.update(this.salle)
        this.salles[this.findIndexById(this.salle.id)] = this.salle;
        this.$toast.add({severity:'success', summary: 'Terminé', detail: this.salle.name + ' a bien été modifié', life: 3000
          });
        }
      else {
        this.salleService.create(this.salle).then(data => {
          _this.salle = data;
          this.salles.push(this.salle)
          this.$toast.add({
            severity: 'success',
            summary: 'Terminé',
            detail: this.salle.name + ' a bien été créé',
            life: 3000
          });
        });
      }
      this.createSalleDialog = false;
      this.submitted = false;

    },
    editSalle(salle) {
      this.step = 1;
      this.salle = {...salle};
      this.createSalleDialog = true;
      this.isCreation = false;
    },

    confirmDeleteSalle(salle) {
      console.log(salle)
      this.salle = salle;
      this.deleteSalleDialog = true;
    },
    deleteSalle() {
      this.salles = this.salles.filter(val => val.id !== this.salle.id);
      this.salleService.delete(this.salle);
      this.deleteSalleDialog = false;
      this.salle = {};
      this.$toast.add({severity:'success', summary: 'Terminé', detail: 'Salle supprimé', life: 3000});

    },


    findIndexById(id) {
      let index = -1;
      for (let i = 0; i < this.salles.length; i++) {
        if (this.salles[i].id === id) {
          index = i;
          break;
        }
      }
      return index;
    },

  }
}
</script>

<style scoped>

</style>